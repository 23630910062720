import Swal from 'sweetalert2'

export class NotificationUtil {
    static error = (msg) => {
        Swal.fire({
            title: 'Error',
                text: msg,
                icon: 'error'
          })
    };

    static success = (msg) => {
        Swal.fire({
            title: 'Exito',
                text: msg,
                icon: 'success'
          })
    };
}


