import { useState } from 'react';


export const useForm = ( initialState = {} ) => {
    
    const [values, setValues] = useState(initialState);

    const reset = () => {
        setValues( initialState );
    }

    const setData = (data) => {
        setValues( data );
    }


    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [ target.name ]: (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value
        });
    }




    return [ values, handleInputChange, reset, setData ];

}