import Swal from 'sweetalert2'
export const MessageUtil = (type, title, msg) => {

    Swal.fire({
        title,
        text: msg,
        icon: type,
      })
}

