import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { NavLink as RouterLink } from 'react-router-dom';
import {List, ListItemButton, ListItemIcon, ListItemText, ListItem, ListSubheader } from '@mui/material';
import { Collapse } from '@material-ui/core';

export const MenuList = () => {
    
  const [ menu, setMenu ] = React.useState({});

  const listamenu = JSON.parse(sessionStorage.getItem('AUTH_MENU'));
  let menuParents = [];
  let menuResult = [];
  if (listamenu.length > 0) {
      menuParents = listamenu.filter((menu, index) => menu.TIPO_OPCION === 1);
      menuParents.forEach(element => {
          let tempChildren = listamenu.filter((child, idx) => child.ID_OPCION_PADRE === element.ID_OPCION);
          tempChildren.sort((a, b) => a.ORDEN - b.ORDEN);
          if (tempChildren.length > 0) {
              element['children'] = tempChildren;
          }
      });
      const menuResult = menuParents.filter(e => (!e.ID_OPCION_PADRE || e.ID_OPCION_PADRE == 0) && e.fields?.length > 0);
      menuResult.sort((a, b) => a.ORDEN - b.ORDEN);
  }

  const handleClick = (item) => {
      let newData = {...menu, [item] : !menu[item]};
      setMenu(newData);
  }

  const CustomRouterLink = React.forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));

    
  const handleMenu = ( children, level=0 ) => {
    return children?.map(({children, name, url, ICON, links}) => {
        if ( !children ) {
              return (
                <List component="div" disablePadding key={name}>
                  <ListItemButton component={CustomRouterLink} to={url} onClick={() => { localStorage.setItem('opcion', JSON.stringify({name, ICON}))}}>
                    <ListItemIcon>
                      <i className={ICON}></i>
                    </ListItemIcon>
                    <ListItemText primary={ name } />
                  </ListItemButton>
                </List>
              )
        }
        return (
          <List component="div" disablePadding key={ name }>
          <ListItemButton onClick={() => handleClick(name)}>
            <ListItemIcon>
              <i className={ICON}></i>
            </ListItemIcon>
            <ListItemText primary={name}/>
              {(menu[ name ]) ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        <Collapse in={ (menu[ name ]) ? true : false} timeout="auto" unmountOnExit>
          { handleMenu( children, 1) }
        </Collapse>
        </List>
        )
    })
  }

  return handleMenu(menuParents);
}