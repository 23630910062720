import React from 'react';
import { ContainerRoutes } from '../../routers/ContainerRoutes';
import { LoadingPage } from '../loading/LoadingPage'


const ContainerLayout = () => {
    return (
        <>
          <ContainerRoutes />
          
          <LoadingPage id="loader"/>
        </>
    );
};

export default ContainerLayout;