export const menu =
    // [
    //     {
    //         'icon':'icon-user',
    //         'label':'Registro de Donaciones',
    //         'url':'/donaciones/registrar-donacion/registrar-donacion-admin',
    //         'key':'1'
    //     },
    //     {
    //         'icon':'icon-user',
    //         'label':'Registrar Envío de Donaciones',
    //         'url':'/donaciones/registrar-envio/registrar-envio-admin',
    //         'key':'2'
    //     },
    // ]
    [
        {
            "name": "Usuarios",
            "url": "/mantenedores/usuarios/usuarios-admin"
        },
        {
          "name": "Item2",
          "url": "/item2"
        },
        {
          "name": "Item3",
          "children": [
            {
              "name": "Child31",
              "url": "/donaciones/registrar-envio/registrar-envio-admin"
            },
            {
              "name": "Child32",
              "url": "/child32"
            },
            {
              "name": "Child33",
              "url": "/child33"
            }
          ]
        },
        {
          "name": "Item4",
          "children": [
            {
              "name": "Child41",
              "url": "/child41"
            },
            {
              "name": "Child42",
              "url": "/child42"
            }
          ]
        }
      ]
