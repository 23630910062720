import React, { lazy, Suspense } from 'react'
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom"
import { LoadingPage } from '../framework/loading/LoadingPage';

const MantenedoresRoutes = lazy(() => import('./mantenedores/MantenedoresRoutes'));
const EmpresaRoutes = lazy(() => import('./empresa/EmpresaRoutes'));
const SeguridadRoutes = lazy(() => import('./seguridad/SeguridadRoutes'));


export const ModulesRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Suspense fallback={<LoadingPage />}>
            <Switch>

                <Route path={`${path}mantenedores`} component={MantenedoresRoutes} />
                <Route path={`${path}empresa`} component={EmpresaRoutes} />
                <Route path={`${path}seguridad`} component={SeguridadRoutes} />

            </Switch>
        </Suspense>
    )
}

//https://learnwithparam.com/blog/how-to-handle-query-params-in-react-router/