
export class UserUtil{

    static getUserId(){
        if(sessionStorage.getItem('AUTH_USER') != '') {
            const auth = sessionStorage.getItem('AUTH_USER');
            const user = JSON.parse(auth);
            return user[0].userId;
        } else {
            return null;
        }
    }

    static getKey(){
        return (Math.random() + 1).toString(36).substring(7);
    }

    static getUserAuth(){
        if(sessionStorage.getItem('AUTH_USER') != '') {
            const auth = sessionStorage.getItem('AUTH_USER');
            const user = JSON.parse(auth);
            return user[0];
        } else {
            return null;
        }
    }

}