import { EnvConstants } from "../EnvConstants";
import { RequestUtil } from "./RequestUtil";
import { StorageUtil } from "./StorageUtil";
import { WindowUtil } from "./WindowUtil";


export class SecurityUtil {
    static async login(username, password) {
        let resp = await RequestUtil.postData(EnvConstants.GET_URL_AUTH() + "/login", { username, password });
        if (resp) {
            await SecurityUtil.setLogin(resp);
            WindowUtil.reload();
        }
    }

    static async register(NOMBRES, APELLIDO_PATERNO) {
        let resp = await RequestUtil.postData(EnvConstants.GET_URL_WRITE(), { NOMBRES, APELLIDO_PATERNO });
        if (resp) {
            await SecurityUtil.setLogin(resp);
            WindowUtil.reload();
        }
    }
    static async loginWithoutReload(params) {
        try {
            let resp = await RequestUtil.postData(EnvConstants.GET_URL_AUTH() + "/login", { username: params.username, password: params.password });
            if (resp) {
                await SecurityUtil.setLogin(resp);
                if (params.fnOk) {
                    params.fnOk();
                }
            }
        } catch (error) {
            if (params.fnError) {
                params.fnError();
            }
        }
    }
    static async loginFromUrlWithLogin() {
        //let resp = await RequestUtil.postData(EnvConstants.GET_URL_AUTH() + "/login", {username: params.username, password: params.password});
    }
    static async setFull() {
        console.log('setFull');
        await StorageUtil.setItemObject('AUTH_FULL', { full: 1 });
    }
    static async setLogin(resp) {
        // await LoginUtil(resp);

        await StorageUtil.setItemObject('AUTH_USER', resp.info?.dataObject?.user);
        await StorageUtil.setItemObject('AUTH_MENU', resp.info?.dataObject?.menu);
        resp.info = null;
        await StorageUtil.setItemObject('AUTH', resp);
    }
    static async setLogout() {
        console.log('setLogout');
        await StorageUtil.removeItemObject('AUTH');
        await StorageUtil.removeItemObject('AUTH_USER');
        await StorageUtil.removeItemObject('AUTH_MENU');
        await StorageUtil.removeItemObject('AUTH_FULL');
        await StorageUtil.removeItemObject('AUTH_URL');
        WindowUtil.reload();
    }
    static getMenu() {
        //console.log(StorageUtil.getItemObject('AUTH_MENU'));
        return StorageUtil.getItemObject('AUTH_MENU');
    }
    static getAccessToken() {
        return StorageUtil.getItemObject('AUTH')?.accessToken;
    }
    static getUsername() {
        return StorageUtil.getItemObject('AUTH')?.userName;
    }
    static getUser() {
        return StorageUtil.getItemObject('AUTH_USER');
    }
    static isAuthenticated() {
        return StorageUtil.getItemObject('AUTH') != null;
    }
    static isFull() {
        return StorageUtil.getItemObject('AUTH_FULL') != null;
    }
    static setUrlWithLogin(data) {
        return StorageUtil.setItemObject('AUTH_URL', data)
    }


}
