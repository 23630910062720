import React from 'react';
import { useDispatch } from 'react-redux';
import { menu } from '../data/menu';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthModuleRoutes } from '../auth_module/AuthModuleRoutes';
import ContainerLayout from '../framework/layout/ContainerLayout';
import { SecurityUtil } from '../util/SecurityUtil';
import { StorageUtil } from '../util/StorageUtil';
import { UrlUtil } from '../util/UrlUtil';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { LoadingPage } from '../framework/loading/LoadingPage';
import Dashboard from '../framework/layout/dashboard-layout/DashboardLayout';

export const AppRouter = ({ match, location }) => {    
    const [ isLoad, setIsLoad ] = React.useState(false);
    const [ isFull, setIsFull ] = React.useState(false);
    const [ isLoggedIn, setIsLoggedIn ] = React.useState(false);

    StorageUtil.setItemObject('USER_BAR', {
        title: 'Cuenta',
        fields: [
            { label: 'Ajustes', icon: 'fas fa-user' },
            { label: 'Logout', icon: 'icon-user', onclick: "" }
        ]
    });


    StorageUtil.setItemObject('USER_MENU', {
        title: 'Cuenta',
        fields: menu
    }); 
    React.useEffect(() => {
        setIsLoad(true);
        if (SecurityUtil.isAuthenticated()){
            setIsLoggedIn(true);
        }
        else {
            const par = UrlUtil.paramsUrlToObject();
            if (par && par?.params){
                let params = JSON.parse(atob(par.params));
                if (params?.full===1){
                    SecurityUtil.setFull();
                }

                if(params?.username){
                    setIsLoad(false);
                    SecurityUtil.setUrlWithLogin(par.params);
                    SecurityUtil.loginWithoutReload({
                        username: params.username, 
                        password: params.password, 
                        fnOk: ()=> {
                            setIsLoggedIn(true);
                            setIsLoad(true);
                        },
                        fnError: () => {
                            setIsLoad(true);
                        }
                    });
                }
            }
        }
        setIsFull(SecurityUtil.isFull());
    }, []);

    if (!isLoad){
        return (<LoadingPage id="loader"/>)
    }

    if (isFull){
        return (
            <Switch>
                <Route path="/" component={ContainerLayout} />    
            </Switch>
        )
    }

    
    return (
        <Switch>   
            <PublicRoute path="/auth" component={ AuthModuleRoutes } isAuthenticated={ isLoggedIn } />
            <PrivateRoute isAuthenticated={ isLoggedIn } path="/" component={ Dashboard } />
            <Redirect to="/auth/login" />
        </Switch>
    )
}