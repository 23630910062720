import React, { useState } from 'react';
import { SecurityUtil } from '../../util/SecurityUtil';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { MessageUtil } from '../../util/MessageUtil';
import { useForm } from './../../hooks/useForm';
import logo from '../../assets/img/LOGO.png';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '150px',
    height: '180px',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginPage = (props) => {
  const classes = useStyles();

  const [loginForm, handleInputChange, reset] = useForm({ username: '', password: '' });

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('values', loginForm['username'], loginForm['password'])
    if (loginForm['username'] !== '' && loginForm['password'] !== '') {
      try {
        SecurityUtil.login(loginForm['username'], loginForm['password']);
        localStorage.setItem(
          "opcion",
          JSON.stringify({ name: "", ICON: "fas fa-user" })
        );
      }
      catch (error) {
        return MessageUtil('error', 'Usuario o Password inválido', error);
      }
    }
    else {
      return MessageUtil('warning', 'Ingrese Usuario y Contraseña', '');
    }
  }

  return (
    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src={logo} variant="rounded">

        </Avatar>
        <Typography component="h1" variant="h5">
          Bienvenido a Burea Veritas - Produce
        </Typography>
        <form className={classes.form} autoComplete="off">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Usuario"
            name="username"
            autoComplete="off"
            autoFocus
            size="small"
            onChange={handleInputChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            size="small"
            onChange={handleInputChange}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
            style={{ backgroundColor: '#B0002D' }}
          >
            Iniciar Sesión
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
    </Container >
  );
};

export default LoginPage;
